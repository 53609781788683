import React from "react"
import styled from "@emotion/styled"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 96px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>404.</h1>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
