import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import DefaultHeader from "../components/header/header"
import Footer from "../components/footer"

const Wrapper = styled.div`
  transition: ${props => props.theme.transitionTime}s;
  background: ${props => props.theme.backgroundColor};

  min-height: calc(100vh);
`

const Main = styled.main`
  padding: 0 75px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1420px;
  color: ${props => props.theme.fontColor};
  min-height: calc(100vh - 240px);

  @media (max-width: 700px) {
    padding: 0px 20px;
    min-height: calc(100vh - 160px);
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Layout = ({ children, sticky, collapsableOnScroll }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  const headerProps = {
    sticky: sticky || false,
    collapsableOnScroll: collapsableOnScroll || false,
  }

  return (
    <Wrapper>
      <DefaultHeader
        menuLinks={site.siteMetadata.menuLinks}
        siteTitle={site.siteMetadata.title}
        {...headerProps}
      />
      <Main>{children}</Main>
      <Footer author={site.siteMetadata.author} />
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
